<template>
    <app-wrapper>
        <template v-slot:child>
            <InventoryHeader :type="'SERVICE'" :title="'Products & Services'" />

            <template v-if="!allProducts">
                <Spinner />
            </template>

            <!-- desktop / mobile content display -->
            <div v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(9)">
                <div class="scrollmenu w-90 center">
                    <table class="w-100 tl mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 pr3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Service ID</span>
                                    </span>
                                </th>
                                <th class="pb3 pr5 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Product Name</span>
                                        <span>
                                            <span class="header-filter-sort-icon">
                                                <span v-if="state.isOpen && getTheValue == 'name'" class="expand sort1"
                                                    @click="sortTransactionsTable('asc', 'name')">
                                                    <img :src="require('@/assets/images/sort-up.png')"
                                                        class="directional-up" alt="" />
                                                </span>
                                                <span v-else class="expand sort1"
                                                    @click="sortTransactionsTable('desc', 'name')">
                                                    <img :src="require('@/assets/images/caret-down.png')"
                                                        class="directional-up" alt="" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <!-- <th class="pb3 box-border-bottom tl">Brand</th> -->
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Type</span>
                                        <span>
                                            <span class="header-filter-sort-icon">
                                                <span v-if="state.isOpen && getTheValue == 'categoryName'" class="expand sort1"
                                                    @click="sortTransactionsTable('asc', 'categoryName')">
                                                    <img :src="require('@/assets/images/sort-up.png')"
                                                        class="directional-up" alt="" />
                                                </span>
                                                <span v-else class="expand sort1"
                                                    @click="sortTransactionsTable('desc', 'categoryName')">
                                                    <img :src="require('@/assets/images/caret-down.png')"
                                                        class="directional-up" alt="" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <!-- <th class="pb3 box-border-bottom tl">Quantity</th> -->
                                <th class="pb3 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Price</span>
                                        <span>
                                            <span class="header-filter-sort-icon">
                                                <span v-if="state.isOpen && getTheValue == 'unitPrice'" class="expand sort1"
                                                    @click="sortTransactionsTable('asc', 'unitPrice')">
                                                    <img :src="require('@/assets/images/sort-up.png')"
                                                        class="directional-up" alt="" />
                                                </span>
                                                <span v-else class="expand sort1"
                                                    @click="sortTransactionsTable('desc', 'unitPrice')">
                                                    <img :src="require('@/assets/images/caret-down.png')"
                                                        class="directional-up" alt="" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <!-- <th class="pb3 box-border-bottom tl">Total</th> -->
                            </tr>
                        </thead>

                        <template v-if="allProducts?.data?.length">
                            <template v-for="product of allProducts?.data" v-bind:key="product._id">
                                <tr class="font-w1 pointer" @click="handleClickedProduct(product._id)">
                                    <td class="pv1 pr4 box-border-bottom tl">{{ product._id.toUpperCase().slice(1, 10)
                                        }}</td>

                                    <template v-if="role === 'admin' || role === 'owner'">
                                        <router-link :to="{ name: 'Service', params: { id: product._id } }">
                                            <td class="pv1 pr4 box-border-bottom tl flex items-center">
                                                <img :src="product?.imageUrl || require('@/assets/images/inventory-default.png')"
                                                    class="pr1 h-25 w-25 cover"
                                                    style="object-position: center; height: 40px; width: 40px; gap: 1rem"
                                                    :alt="product.name" />
                                                <span class="font-w2">{{ product.name }}</span>
                                            </td>
                                        </router-link>
                                    </template>

                                    <td v-else class="pv1 pr4 box-border-bottom tl flex items-center tooltip">
                                        <img :src="product?.imageUrl || require('@/assets/images/inventory-default.png')"
                                            class="pr1 h-25 w-25 cover"
                                            style="object-position: center; height: 40px; width: 40px; gap: 1rem"
                                            :alt="product.name" />
                                        <span class="font-w2">{{
                                            product.name?.length > 14 ? `${product.name?.slice(1, 14)}...` :
                                            product.name
                                            }}</span>
                                        <span v-if="product.name?.length > 14" class="tooltiptext"
                                            style="padding: 10px;">{{
                                            product.name
                                            }}</span>
                                    </td>

                                    <!-- <td class="pv1 pr4 box-border-bottom tl">{{ product?.brandName || '-' }}</td> -->
                                    <td class="pv1 pr4 box-border-bottom tl">
                                        <span class="br-pill ph2 pv1" style="background: #d7defa">{{
                                            product?.categoryName || '-'
                                            }}</span>
                                    </td>
                                    <!-- <td class="pv1 pr4 box-border-bottom tl">
                                        {{
                                            formatQuantity(
                                                product?.purchase_qty
                                                    ? parseFloat(product?.purchase_qty) - parseFloat(product?.sales_qty || 0)
                                                    : 0
                                            )
                                        }}
                                    </td> -->
                                    <!-- <td class="pv1 pr4 box-border-bottom tl">{{ formatAmount(product.purchaseAmount) }}</td>-->
                                    <!--<td class="pv1 pr4 box-border-bottom tl">{{ formatAmount(product.purchase_qty * product.purchaseAmount) }}</td>-->
                                    <td class="pv1 pr4 box-border-bottom tl">
                                        {{ formatAmount(product?.unitPrice, $store.state.Settings?.currency) }}
                                    </td>
                                    <!-- <td class="pv1 pr4 box-border-bottom tl">
                                        <span v-if="product?.purchase_qty && product?.sales_qty">
                                            {{
                                                formatAmount(
                                                    (parseFloat(product?.purchase_qty) - parseFloat(product?.sales_qty)) *
                                                        product?.buyingPrice
                                                )
                                            }}
                                        </span>

                                        <span v-else>
                                            {{ formatAmount(product.purchase_qty * product?.buyingPrice) }}
                                        </span>
                                    </td> -->
                                </tr>
                            </template>
                        </template>
                    </table>
                    <TableEmptyState v-if="!allProducts?.data?.length" :data-array="allProducts?.data" />
                </div>

                <Pagination v-if="allProducts?.data?.length" :pages="allProducts?.pagination" route-name="Services"
                    :onNavigate="onNavigate" />
            </div>
        </template>
    </app-wrapper>
</template>

<script>
    import { onMounted, computed, reactive, ref } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute, useRouter } from 'vue-router'
    import { formatAmount, formatQuantity, sortByNumericData, sortAlphaNumericData } from '@/utils/lib'
    import AppWrapper from '@/layout/AppWrapper'
    import InventoryHeader from '@/views/Inventory/widgets/InventoryHeader'
    import Pagination from '@/components/Pagination'

    import Spinner from '@/components/Spinner'
    import TableEmptyState from '@/components/TableEmptyState'
    // import Filter from '@/components/Filter'

    export default {
        name: 'Services',
        components: { AppWrapper, InventoryHeader, Pagination, Spinner, TableEmptyState },
        data: () => ({
            isOpen: true
        }),
        setup() {
            const router = useRouter()
            const store = useStore()
            const allProducts = computed(() => store?.state.Inventory.products)
            // const productPages = computed(() => store?.state.Inventory.productPages)
            const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
            const role = computed(() => store?.state?.Auth?.role)
            const route = useRoute()
            const getTheValue = ref('')

            const state = reactive({
                isOpen: true
            })

            const onNavigate = query => {
                store.dispatch('Inventory/getAllServices', query)
            }

            const handleClickedProduct = id => {
                if (role?.value === 'admin' || role?.value === 'owner') {
                    router.push({ name: 'Service', params: { id } })
                }
            }

            onMounted(() => {
                const query = route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null
                store.dispatch('Inventory/getAllServices', query)
            })

            const refreshHandler = () => {
                store.dispatch('Inventory/getAllServices')
            }

            const sortTransactionsTableOld = (order, value) => {
                const query = {
                    data: allProducts.value.data,
                    order: order,
                    value: value
                }
                if (value === 'unitPrice') sortByNumericData(query)
                else {
                    sortAlphaNumericData(query)
                }
            }

            const sortTransactionsTable = (mode, sortBy) => {
                state.isOpen = !state.isOpen
                getTheValue.value = sortBy
                const query = {
                    filterType: route.query.filterType || 'customrange',
                    limit: route.query.limit || 25,
                    page: route.query.page || 1,
                    sort: route.query.sort || sortBy,
                    mode: route.query.mode || mode,
                }

                store.dispatch('Inventory/getAllServices', query)
            }

            return {
                state,
                allProducts,
                formatAmount,
                handleClickedProduct,
                refreshHandler,
                formatQuantity,
                onNavigate,
                role,
                rolePermissions,
                sortTransactionsTable,
                getTheValue
            }
        }
    }
</script>

<style scoped></style>